import React from 'react'
import BlogTemplate from 'templates/blog-template'
import { graphql } from 'gatsby'

const BlogCategoryTag = props => {
  return (
    <BlogTemplate
      header={`Blog - ${props.pageContext.category} ${props.pageContext.tag}`}
      {...props}
    />
  )
}

export const pageQuery = graphql`
  query blogCategoryTag($category: String!, $tag: String!) {
    allSanityBlogPost(
      sort: { fields: publishedDate, order: DESC }
      filter: {
        tags: { elemMatch: { titlePlural: { eq: $tag } } }
        category: { titlePlural: { eq: $category } }
        publishedDate: { ne: null }
      }
    ) {
      nodes {
        authors
        category {
          slug
          titlePlural
        }
        id
        featuredImage {
          asset {
            gatsbyImageData(width: 750)
          }
        }
        slug
        title
      }
    }
  }
`

export default BlogCategoryTag
